class TrackFormChanges {
  constructor() {
    if ($('[data-behavior="track-form-changes"]').length) {
      this.updateNewDealForm($('[data-behavior="track-form-changes"]'));
    }
  }
};

TrackFormChanges.prototype.updateNewDealForm = function ($form) {
  var form = $form;
  var form_data = form.serialize();

  // This function validates whether the form is unchanged and if the contents of
  // the form itself are valid to determine if the form needs saving prior to
  // accessing the match tool.
  var toggleBtn = function () {
    if (form_data == form.serialize() && form[0].checkValidity()) {
      form.find(":submit").hide().prop("disabled", true);
      form.find(".queue-agents-link").show();
      form.find(".gpt-pre-acceptance-note").removeClass("pointer-events-none opacity-50");
    } else {
      form.find(":submit").show().prop("disabled", false);
      form.find(".queue-agents-link").hide();
      form.find(".gpt-pre-acceptance-note").addClass("pointer-events-none opacity-50");
    }
  }

  // Toggle selling address for buyers that indicated they're also selling _and_
  // provided their address.
  var alsoSelling = form.find("[data-toggle-with-also-selling]");
  if (alsoSelling) {
    $('input[type=radio][name="deal[selling_also]"]').change(function () {
      if (this.value === 'true' && this.checked) {
        alsoSelling.removeClass('hidden');
      } else {
        alsoSelling.addClass('hidden');
      }
    });
  }

  // We confirm validity on each input event
  form.on("input", ":input", toggleBtn);

  // We trigger this function on page load to ensure all required form fields
  // have been filled out before accessing the match tool.
  toggleBtn();
};

$(() => {
  return Page.trackFormChanges = new TrackFormChanges();
});
